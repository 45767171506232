import { Utils } from '../utils/Utils';

// *****************************************************
// Details(アコーディオン)クラス
// *****************************************************
export class Details {
  constructor() {
    // アニメーションの時間とイージング
    const animTiming = {
      duration: 400,
      easing: "ease-out"
    };
    // アコーディオンを閉じるときのキーフレーム
    const closingAnimKeyframes = (content: HTMLElement) => [
      {
        height: content.clientHeight + 'px', // height: "auto"だとうまく計算されないため要素の高さを指定する
        opacity: 1,
      },
      {
        height: 0,
        opacity: 0,
      }
    ];
    // アコーディオンを開くときのキーフレーム
    const openingAnimKeyframes = (content: HTMLElement) => [
      {
        height: 0,
        opacity: 0,
      },
      {
        height: content.clientHeight + 'px',
        opacity: 1,
      }
    ];

    const accordions = document.getElementsByTagName('details'); // アコーディオン
    for (let i = 0; i < accordions.length; i++) {
      const details = accordions[i];
      const summary = details.querySelector('summary');
      const contents = details.querySelector('.js-detailsContents');
      if (summary && contents) {
        summary.addEventListener('click', (e) => {
          e.preventDefault(); // 規定の挙動を無効化
          if (details.open) {
            // アコーディオンを閉じる
            const closingAnim = contents.animate(closingAnimKeyframes(<HTMLElement>contents), animTiming);
            closingAnim.onfinish = () => {
              // アニメーションの完了後にopen属性を取り除く
              details.removeAttribute("open");
            };
          }
          else {
            // アコーディオンを開くときの処理
            details.setAttribute("open", "true");
            const openingAnim = contents.animate(openingAnimKeyframes(<HTMLElement>contents), animTiming);
          }
        });
      }
    }
  }
}
