// import Example from "./utils/Example";

// class Main {
//   constructor() {
//     const ex = new Example();
//     ex.consooole("web starter kit");
//   }
// }

// window.addEventListener("DOMContentLoaded", () => {
//   new Main();
// });
// import MicroModal from "micromodal";
// MicroModal.init({
//   onClose,
//   disableScroll: true,
//   disableFocus: true,
//   awaitOpenAnimation: true,
//   awaitCloseAnimation: true,
// });
// const onClose = () => {};
