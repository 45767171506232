import { General } from "./class/General";
import { Hamburger } from "./class/Hamburger";
import { Details } from "./class/Details";
import { SwiperOperate } from "./class/SwiperOperate";
import { Tab } from "./class/Tab";
import { Loader } from "@googlemaps/js-api-loader";
// import { AdvancedMarkerElement } from "google.maps";

window.addEventListener("DOMContentLoaded", () => {
  setLoading(); // ローディングアニメーション
  setSmallWindow(); // 小さいウィンドウサイズ対応
  setAnimation(); // アニメーションの登録

  const general = new General();
  // general.setAnchorPosition(); // アンカーリンクの位置調整（ページロード時用）
  general.setSmoothScroll(); // スムーススクロール
  const hmb = new Hamburger(); // ハンバーガーメニュー
  const dtls = new Details(); // detailsを使用したアコーディオン
  const so = new SwiperOperate(); // Swiper
  const tab = new Tab();

  setCloseBnrBtn(); // バナーの閉じるボタンをセット
  setCurrentLeftNav(); // 左カラムのカレントアンカーリンク対応

  setMap(); // GoogleMAPの設定
});

// -------------------------------------
// 機能：ローディングアニメーションの登録
// -------------------------------------
function setLoading() {
  // ローディング画面
  const loadingArea = document.getElementById("loadingAnime"); // ローディング画面全体
  if (loadingArea) {
    const images = document.getElementsByTagName("img"); // ページ内のimgタグを取得
    const percentNumber = document.getElementById("loadingAnime_percentNum"); // ●%の●部分
    const loadingGauge = document.getElementById("loadingAnime_gaugeLine"); // リアルタイムで読み込まれるゲージ部分
    let imgCounting = 0;
    let baseCounting = 0;
    const gaugeWidth = 100; // ゲージの全体幅
    let current;

    // 画像の読み込み
    for (let i = 0; i < images.length; i++) {
      const img = new Image(); // 新たなimg要素を作成
      // 画像読み込み完了したとき
      img.onload = function () {
        imgCounting += 1;
      };
      // 画像読み込み失敗したとき
      img.onerror = function () {
        imgCounting += 1;
      };
      img.src = images[i].src; // ソースのパスを設定
    }
    // console.log(images.length);
    // setIntervalを使って一定時間ごとに処理を繰り返す
    const nowLoading = setInterval(function () {
      // baseCountingがimgCountingより大きくならない条件の場合に処理を実行させる。2回目以降にページを読み込んだ時に画像の読み込み履歴が残っている関係で、ローディング画面の表示が速く終わってしまうため、その対策として条件をつけている。
      if (baseCounting <= imgCounting) {
        baseCounting += 1;
        // リアルタイムで読み込んでいるパーセントを取得
        current = Math.floor((baseCounting / images.length) * 100);
        // ●%の●部分に数字を置き換える
        if (percentNumber) {
          percentNumber.innerHTML = current.toString();
        }
        // リアルタイムで読み込まれるゲージ部分を反映させる
        if (loadingGauge) {
          loadingGauge.style.width =
            Math.floor((gaugeWidth / 100) * current) + "%";
        }
      }
      // 全て読み込んだ時
      if (baseCounting === imgCounting) {
        clearInterval(nowLoading);
        if (percentNumber) {
          percentNumber.innerHTML = "100";
        }
        if (loadingGauge) {
          loadingGauge.style.width = "100%";
        }
        setTimeout(function () {
          loadingArea.style.opacity = "0";
          setTimeout(function () {
            loadingArea.style.display = "none";
          }, 300); // フェードアウトのための遅延
        }, 500); // 少しの遅延を加える
      }
    }, 50);
  }
}

// -------------------------------------
// 機能：小さい画面の場合は、360pxの画面を縮小したものにする
// -------------------------------------
function setSmallWindow() {
  const viewport = document.querySelector('meta[name="viewport"]');
  function switchViewport() {
    if (viewport) {
      const value =
        window.outerWidth > 374
          ? "width=device-width,initial-scale=1"
          : "width=375";
      if (viewport.getAttribute("content") !== value) {
        viewport.setAttribute("content", value);
      }
    }
  }
  addEventListener("resize", switchViewport, false);
  switchViewport();
}

// -------------------------------------
// 機能：アニメーションの登録
// -------------------------------------
function setAnimation() {
  // const boxes = document.querySelectorAll(".scrollIn");
  const scrollIns = document.querySelectorAll(".scrollIn");
  // const boxesArr = Array.prototype.slice.call(boxes);

  const options = {
    root: null, // 今回はビューポートをルート要素とする
    rootMargin: "0px 0px -20% 0px", // ビューポートの中心を判定基準にする
    threshold: 0,
  };
  const observer = new IntersectionObserver(callback, options);
  if (scrollIns) {
    scrollIns.forEach(function (box) {
      observer.observe(box);
    });
  }

  function callback(entries: any) {
    entries.forEach(function (entry: any, i: number) {
      const target = entry.target;
      if (entry.isIntersecting && !target.classList.contains(".active")) {
        const delay = i * 50;
        setTimeout(function () {
          target.classList.add("active");
        }, delay);
      }
    });
  }
}

// -------------------------------------
// バナーの閉じるボタンをセット
// -------------------------------------
function setCloseBnrBtn() {
  const fixedBnr_btn = document.querySelectorAll(".fixedBnr_btn");
  for (let i = 0; i < fixedBnr_btn.length; i++) {
    const bnr = fixedBnr_btn[i].closest(".fixedBnr_bnr");
    if (bnr) {
      fixedBnr_btn[i].addEventListener(
        "click",
        () => {
          (<HTMLElement>bnr).style.display = "none";
        },
        { once: true }
      );
    }
  }
}

// -------------------------------------
// 機能：施設のMAPの登録
// -------------------------------------
async function setMap() {
  // 施設のマップ登録
  const mapArea = document.getElementById("facility-map");
  if (mapArea) {
    let mapCenter = { lat: 26.2125477, lng: 127.681251 }; // マップの中央位置
    let markerCenter = { lat: 26.2125477, lng: 127.681251 }; // マーカーの中央位置
    // 施設の種類から、マップとマーカーの中央位置を決める
    const facility = mapArea.dataset.facility;
    if (facility === "headoffice") {
      mapCenter = { lat: 26.2335, lng: 127.7037 };
      markerCenter = { lat: 26.2335, lng: 127.7037 };
    } else if (facility === "nahagakuen") {
      mapCenter = { lat: 26.2335, lng: 127.7037 };
      markerCenter = { lat: 26.2335, lng: 127.7037 };
    }
    else if (facility === "tamagawaen") {
      mapCenter = { lat: 26.14634, lng: 127.75003 };
      markerCenter = { lat: 26.14634, lng: 127.75003 };
    }
    else if (facility === "ijunoki") {
      mapCenter = { lat: 26.21357, lng: 127.72763 };
      markerCenter = { lat: 26.21357, lng: 127.72763 };
    }

    // マップとマーカーをセット
    const { Map } = (await google.maps.importLibrary(
      "maps"
    )) as google.maps.MapsLibrary;
    const { AdvancedMarkerElement } = (await google.maps.importLibrary(
      "marker"
    )) as google.maps.MarkerLibrary;
    const map = new Map(
      document.getElementById("facility-map") as HTMLElement,
      {
        center: mapCenter,
        zoom: 17,
        mapId: "DEMO_MA_ID",
      }
    );
    const pin = document.createElement("div");
    pin.classList.add("pin");
    if (facility === "headoffice") {
      pin.innerHTML =
        '<img src="/ijunokikai/wp/wp-content/themes/ijunokikai/_assets/images/mapspin.png" width="73" height="77">';
    } else {
      pin.innerHTML =
        '<img src="/ijunokikai/wp/wp-content/themes/ijunokikai/_assets/images/mapspin_facility.png" width="55" height="64">';
    }

    const marker = new AdvancedMarkerElement({
      map,
      position: markerCenter,
      content: pin,
    });
  }
}

// -------------------------------------
// 左カラムナビゲーションへのcurrent表示対応
// -------------------------------------
function setCurrentLeftNav() {
  const sideBar = document.getElementById("sidebar");
  if (sideBar) {
    const links = sideBar.querySelectorAll('.ancLinkList a[href^="#"]'); // アンカーリンクリスト
    if (links.length > 0) {
      links.forEach((link) => {
        const href = link.getAttribute("href");
        if (href) {
          const targetId = href.slice(1); // '#' を取り除く
          const targetElement = document.getElementById(targetId);
          if (targetElement) {
            const observer = new IntersectionObserver(
              (entries) => {
                entries.forEach((entry) => {
                  if (entry.isIntersecting) {
                    const sectionId = entry.target.id;
                    if (targetId === sectionId) {
                      // 交差している要素に対応するリンクのみに current クラスを付与
                      links.forEach((l) => l.classList.remove("current")); // すべてのリンクから current クラスを削除
                      link.classList.add("current");
                    }
                  }
                });
              },
              {
                rootMargin: "-39% 0px -50% 0px",
                root: null,
              }
            );
            observer.observe(targetElement);
          }
        }
      });
    }
  }
}
