// *****************************************************
// Swiper
// *****************************************************
import Swiper, {
  Navigation,
  Autoplay,
  Thumbs,
  SwiperOptions,
  EffectFade,
  Pagination,
} from "swiper";

// *****************************************************
// Swiper操作クラス
// *****************************************************
export class SwiperOperate {
  constructor() {
    Swiper.use([Navigation, Autoplay, Thumbs, EffectFade, Pagination]);

    // ****** トップページの受給事例 ******
    const topMv: SwiperOptions = {
      loop: true, // 一番最後まで行ったときに最初に戻る（true）か戻らない（false）か
      autoplay: {
        // 自動再生
        delay: 7000, // 間隔（ミリ秒）
        disableOnInteraction: true, // 操作されたら自動再生をストップさせる（true）設定（規定値true）
      },
      slidesPerView: 1, // 1画面に表示するスライドの枚数
      speed: 800, // スライドが移動する時間（ミリ秒）（規定値は300ms）
      autoHeight: false, // スライド毎の高さに合わせる
      effect: "fade",
      // ページネーション
      pagination: {
        el: ".swiper-pagination",
        clickable: true, //クリックに反応させる
      },
    };
    const topCase = new Swiper(".topMvSlider", topMv);

    // ****** サムネイル有りの画像スライダー ******
    // サムネイル;
    const sliderThumbnail = new Swiper(".thumbSlider", {
      slidesPerView: 4.5,
      spaceBetween: 10,
      // loop: true,
      threshold: 20,
      // centeredSlides: true,
      breakpoints: {
        // スライドの表示枚数：769px以上の場合
        769: {
          spaceBetween: 37,
        },
      },
    });
    // 画像スライダー
    const slider = new Swiper(".pictureSlider", {
      effect: "fade", // フェード
      speed: 100,
      slidesPerView: 1, // 1画面に表示するスライドの枚数
      autoplay: {
        // 自動再生
        delay: 6000, // 間隔（ミリ秒）
        disableOnInteraction: true, // 操作されたら自動再生をストップさせる（true）設定（規定値true）
      },
      // ナビゲーションボタン（前に戻るボタン、次に進むボタン）
      navigation: {
        nextEl: ".pictureSlider_next",
        prevEl: ".pictureSlider_prev",
      },
      thumbs: {
        swiper: sliderThumbnail,
      },
    });
  }
}
