// *****************************************************
// タブ
// *****************************************************
import { Utils } from "../utils/Utils";
// デフォルトのクラス名
namespace ClassName {
  export const CONTAINER = "js-tabContainer"; // タブのコンテナクラス名
}

// *****************************************************
// タブクラス
// *****************************************************
export class Tab {
  private _containerClass: string; // タブのコンテナクラス名

  // ----------------------------------------------------
  // 機能：コンストラクタ
  // 引数：containerClass  タブのコンテナクラス名
  // 返値：なし
  //----------------------------------------------------
  constructor(containerClass?: string) {
    // クラス名の保存
    this._containerClass = containerClass ?? ClassName.CONTAINER;

    const containers = document.querySelectorAll("." + this._containerClass); // タブのコンテナを全て取得
    containers.forEach((container) => {
      const btns = container.querySelectorAll('button[role="tab"]'); // タブのボタン
      if (btns) {
        // タブのボタン押下時のイベント登録
        btns.forEach((btn) => {
          btn.addEventListener("click", () => {
            // 現在選択中の状態をクリア
            container
              .querySelector('button[role="tab"][aria-selected="true"]')
              ?.removeAttribute("tabindex");
            container
              .querySelector('button[role="tab"][aria-selected="true"]')
              ?.setAttribute("aria-selected", "false");
            container
              .querySelector('[role="tabpanel"]:not([hidden])')
              ?.setAttribute("hidden", "true");
            // 選択したものをアクティブとする
            const targetPanel = (<HTMLElement>btn).getAttribute(
              "aria-controls"
            );
            if (targetPanel) {
              btn.setAttribute("tabindex", "-1"); // タブフォーカスが当たらないようにする
              btn.setAttribute("aria-selected", "true");
              document.getElementById(targetPanel)?.removeAttribute("hidden");
            }

            // 施設一覧の場合は、800px以下のときにコンテンツ領域にスクロールする
            if (container.id === "topFacility") {
              if (window.innerWidth <= 800 && targetPanel) {
                // console.log(container);
                const panel = document.getElementById(targetPanel);
                if (panel) {
                  Utils.scrollTo(panel);
                }
              }
            }
          });
        });
      }
    });
  }
}
